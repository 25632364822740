<!--
  需求管理
    + 点击行进入需求详情，自己看的详情界面是表单形式，外部从资源大厅看到的是外部详情
-->
<template>
  <div class="panel">
    <div class="panel_header tw-flex tw-justify-between tw-items-center">
      <page-header title="需求管理" />
    </div>
    <!-- 非普通用户没有“分配的需求” -->
    <el-tabs
      v-if="isShowAllocated"
      v-model="activeTab">
      <el-tab-pane label="发布的需求" name="1"></el-tab-pane>
      <el-tab-pane label="分配的需求" name="2"></el-tab-pane>
      <el-tab-pane label="接受的需求" name="3"></el-tab-pane>
    </el-tabs>

    <!-- 发布的需求 -->
    <published-require v-if="activeTab === '1'" />
    <!-- 分配的需求 -->
    <allocated-require v-else-if="activeTab === '2'" />
    <!-- 接受的需求 -->
    <receiving-require v-else-if="activeTab === '3'"/>
  </div>
</template>

<script>
import publishedRequire from './components/require-list/published-require.vue'
import allocatedRequire from './components/require-list/allocated-require.vue'
import receivingRequire from './components/require-list/receiving-require.vue'

export default {
  components: { publishedRequire, allocatedRequire, receivingRequire },
  data () {
    return {
      // 当前激活项
      activeTab: '1'
    }
  },
  computed: {
    // 是否显示分配的需求 服务用户和服务集成商用户才显示
    isShowAllocated () {
      return this.isServiceOrg || this.isServiceAggregator
    }
  }
}
</script>

<style lang="scss" scoped>
.panel {
  width: 100%;
  min-width: 1030px;
  min-height: 100%;
  padding: 0 30px;
  background: #FFFFFF;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  overflow: auto;
  &_header {
    padding: 20px 0;
  }
  &_op {
    padding: 10px 0;
  }
  &_table {
    padding: 10px 0;
  }
  &_pagination {
    margin: 20px 0;
    height: 40px;
  }
}
</style>
