<!--
  接受的需求
    + 服务用户和服务集成商用户
-->
<template>
  <div>
    <div class="panel_op">
      <el-select
        size="small"
        v-model="select"
        style="margin-left:10px;"
        clearable
        placeholder="请选择需求状态"
        @change="getReceiverRequire">
        <el-option
          v-for="item in globalVar.REQUIRE_STATUS_OPTIONS"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
    </div>
    <div class="panel_table">
      <el-table
        :data="tableData"
        :header-cell-style="headerCellStyle"
        v-loading="loading"
        @row-click="onDetail">
        <el-table-column
          label="需求标题"
          prop="title">
        </el-table-column>
        <!-- @todo 新增需求发布方 -->
        <el-table-column
          label="需求发布方"
          prop="title">
        </el-table-column>
        <!-- @todo 需求类型变化 -->
        <el-table-column
          label="需求类型"
          prop="serviceTypeName">
        </el-table-column>
        <el-table-column
          label="价格（元）"
          prop="budget">
        </el-table-column>
        <el-table-column
          label="需求截止时间"
          prop="endAt">
        </el-table-column>
        <el-table-column
          label="需求状态"
          prop="logicStatus">
          <template slot-scope="scope">
            {{ scope.row.logicStatus | findLabel(globalVar.REQUIRE_STATUS_OPTIONS) }}
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="panel_pagination tw-flex tw-justify-center tw-items-center">
      <el-pagination
        hide-on-single-page
        background
        @current-change="getReceiverRequire"
        :current-page.sync="pagination.pageNum"
        :page-size="pagination.pageSize"
        layout="prev, pager, next, jumper"
        :total="pagination.total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import * as api from '@/api'

export default {
  data () {
    return {
      loading: false,
      select: '',
      tableData: [],
      // 分页对象
      pagination: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      }
    }
  },
  computed: {
    headerCellStyle () {
      return {
        backgroundColor: '#F7F8F9',
        color: '#909399',
        fontWeight: 'bold'
      }
    }
  },
  created () {
    this.getReceiverRequire()
  },
  methods: {
    // 获取需求列表
    getReceiverRequire () {
      this.loading = true
      api.getReceiverRequire({
        ...this.pagination,
        logicStatus: this.select
      }).then(res => {
        if (res.data.code === 0) {
          this.pagination.pageNum = res.data.data.currentPage
          this.pagination.total = res.data.data.total
          this.tableData = res.data.data.list
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
        this.loading = false
      })
    },
    /**
     * 进入需求详情
     * @todo 进入自己的需求则为表单界面
     */
    onDetail (row) {
      window.open(`/require-detail-form/${row.id}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.panel {
  width: 100%;
  min-width: 1030px;
  min-height: 100%;
  padding: 0 30px;
  background: #FFFFFF;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  overflow: auto;
  &_header {
    padding: 20px 0;
  }
  &_op {
    padding: 10px 0;
  }
  &_table {
    padding: 10px 0;
  }
  &_pagination {
    margin: 20px 0;
    height: 40px;
  }
}
</style>
